import React, { FunctionComponent } from 'react'
import FormBuilder, { FormBuilderProps } from 'Root/Shared/components/builders/FormBuilder'
import { t } from 'ttag'

declare global {
    interface Window { generator: any, uniqid: string, dataLayer: any }
}
const GenericTrialForm: FunctionComponent = () => {
    const WORKFLOW_ACTION = "/workflows/Invoke?token=CfDJ8Lddv4IhhiRKi8bRrd_lQeTH5T5q2K1FfTVkmtGP_8HYB0b2p22W3rjCV2kgbyWS6WtECoRYjEQSVdk2Sq3oT6iGM7fwn_8bm_rRJh_PxF-9TlwKJd6wd8qseZfTbnrx_Yzv4bop4NvLQUapW-_yZzdu-L1Uk5RIJIGMCT6_KklrKe0GkMtbaz_APSSK9zvVY2BS9e2RuoGOJbzFDEfa57JD9S5p3U3YSVPwEZuRC94d"
    const inputDefault = {
        "puxFormElementOptions": {
            "formElementOptionsDisabled": false,
            "formElementOptionsAutofocus": false,
            "formElementOptionsAutocomplete": false,
            "formElementOptionsMultiple": false,
            "formElementOptionsStep": 1,
            "formElementOptionsCssClass": "mauticform-input",
            "formElementOptionsReadOnly": false
        },
        "puxFormElementValidation": {
            "formElementValidationRequired": true,
            "formElementValidationPattern": "",
            "formElementValidationMin": undefined,
            "formElementValidationMax": undefined,
            "formElementValidationMinLength": undefined,
            "formElementValidationMaxLength": undefined
        },
        "metadata": {
            "alignment": "Justify",
            "size": 100
        }
    }

    const data: FormBuilderProps = {
        "formBuilderData": {
            "contentType": "PuxForm",
            "displayText": "mauticform_trialrequest",
            "metadata": {
                alignment: "",
                size: 100,
            },
            "puxFormOptions": {
                "formAction": WORKFLOW_ACTION,
                "formMethod": "POST",
                "formEncType": "multipart/form-data",
                "formAntiForgeryToken": false,
                "formID": "mauticform_trialrequest",
                "formSuccessMessage": {
                    "html": ""
                },
                "formCustomCssClass": "",
                "formShowLabels": true,
                "formRedirectUrl": {
                    internal: [false],
                    url: "",
                    text: ""
                }
            },
            "puxFormRecaptchaOptions": {
                "formRecaptchaSiteKey": "6LddcacpAAAAAG0STmwsbcYzM_aqYijcUcMnAF0C",
                "formValidateRecaptcha": false
            },
            "puxWidgetProperties": {
                "widgetPropertiesPartID": "",
                "widgetPropertiesPartMarginTop": "",
                "widgetPropertiesPartPaddingTop": "",
                "widgetPropertiesPartMarginBottom": "",
                "widgetPropertiesPartPaddingBottom": "",
                "widgetPropertiesPartMobileOrder": ""
            },
            "flow": {
                "widgets": [
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[email]",
                        "inputType": "email",
                        "puxFormElement": {
                            "formElementDefaultValue": "",
                            "formElementID": "company-email",
                            "formElementName": "mauticform[email]",
                            "formElementLabel": t`Redmine.TrialCTAForm.Email`,
                            "formElementPlaceholder": t`Redmine.TrialCTAForm.YourEmail`
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[first_name]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_first_name",
                            "formElementName": "mauticform[first_name]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[last_name]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_last_name",
                            "formElementName": "mauticform[last_name]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[identifier]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": IDGenerator(),
                            "formElementID": "mauticform_input_trialrequest_identifier",
                            "formElementName": "mauticform[identifier]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[affiliate_account_id]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_affiliate_account_id",
                            "formElementName": "mauticform[affiliate_account_id]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldInput",
                        "displayText": "mauticform[affiliate_conversion_id]",
                        "inputType": "hidden",
                        "puxFormElement": {
                            "formElementDefaultValue": " ",
                            "formElementID": "mauticform_input_trialrequest_affiliate_conversion_id",
                            "formElementName": "mauticform[affiliate_conversion_id]",
                            "formElementLabel": " ",
                            "formElementPlaceholder": ""
                        },
                        ...inputDefault
                    },
                    {
                        "contentType": "PuxFormFieldSubmit",
                        "displayText": "",
                        "formElementName": t`Redmine.TrialCTAForm.Submit`,
                        "buttonColor": "Button--yellow",
                        "buttonSize": "Button--large",
                        "buttonType": "Button--primary",
                        "metadata": {
                            "alignment": "Justify",
                            "size": 100
                        }
                    }
                ]
            },
            "mautic": {
                "formContainerMauticID": "62",
                "formContainerSource": "trial",
                "formContainerSubmissionID": "www.easyredmine.com;demo;"
            }
        }
    }

    function IDGenerator() {
        const length = 77
        const timestamp = +new Date()

        const _getRandomInt = function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min
        }

        const ts = timestamp.toString()
        const parts = ts.split(``).reverse()
        let id = ``

        for (let i = 0; i < length; ++i) {
            const index = _getRandomInt(0, parts.length - 1)
            id += parts[index]
        }
        return id
    }

    return <FormBuilder {...data} />

    return (
        <div id='mauticform_wrapper_trialrequest' className='mauticform_wrapper'>


            <form
                autoComplete='false'
                role='form'
                method='post'
                action='https://mautic2018.easyredmine.com/form/submit?formId=4'
                id='mauticform_trialrequest'
                data-mautic-form='trialrequest'
                encType='multipart/form-data'
            >
                <div className='mauticform-error' id='mauticform_trialrequest_error' />
                <div
                    className='mauticform-message'
                    id='mauticform_trialrequest_message'
                />
                <div className='mauticform-innerform'>
                    <div
                        className='mauticform-page-wrapper mauticform-page-1'
                        data-mautic-form-page={1}
                    >
                        <div
                            id='mauticform_trialrequest_email'
                            data-validate='email'
                            data-validation-type='email'
                            className='mauticform-row mauticform-email mauticform-field-1 mauticform-required'
                        >
                            <label
                                id='mauticform_label_trialrequest_email'
                                htmlFor='mauticform_input_trialrequest_email'
                                className='mauticform-label'
                            >
                                {t`Redmine.TrialCTAForm.Email`}
                            </label>
                            <input
                                id='mauticform_input_trialrequest_email'
                                name='mauticform[email]'
                                className='mauticform-input'
                                type='email'
                                placeholder={t`Redmine.TrialCTAForm.YourEmail`}
                            />
                            <span className='mauticform-errormsg' style={{ display: `none` }}>
                                {t`Redmine.TrialCTAForm.Required`}
                            </span>
                        </div>
                        <input
                            id='mauticform_input_trialrequest_first_name'
                            name='mauticform[first_name]'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_last_name'
                            name='mauticform[last_name]'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_affiliate_account_id'
                            name='mauticform[affiliate_account_id]'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_affiliate_conversion_id'
                            name='mauticform[affiliate_conversion_id]'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_submission_form_identifier'
                            name='mauticform[submission_form_identifier]'
                            defaultValue='www.easyredmine.com;demo;'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_identifier'
                            name='mauticform[identifier]'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <input
                            id='mauticform_input_trialrequest_source'
                            name='mauticform[source]'
                            defaultValue='trial'
                            className='mauticform-hidden'
                            type='hidden'
                        />
                        <div
                            id='mauticform_trialrequest_recaptcha'
                            className='mauticform-row mauticform-div-wrapper mauticform-field-10'
                        >
                            <label
                                id='mauticform_label_trialrequest_recaptcha'
                                htmlFor='mauticform_input_trialrequest_recaptcha'
                                className='mauticform-label'
                            >
                                reCaptcha
                            </label>
                            <input
                                id='mauticform_input_trialrequest_recaptcha'
                                name='mauticform[recaptcha]'
                                className='mauticform-input'
                                type='hidden'
                            />
                            <span
                                className='mauticform-errormsg'
                                style={{ display: `none` }}
                            />
                        </div>
                        <div
                            id='mauticform_trialrequest_submit'
                            className='mauticform-row mauticform-button-wrapper mauticform-field-11'
                        >
                            <button
                                type='submit'
                                name='mauticform[submit]'
                                id='mauticform_input_trialrequest_submit'
                                className='mauticform-button btn btn-default'
                            >
                                {t`Redmine.TrialCTAForm.Submit`}
                            </button>
                        </div>
                    </div>
                </div>
                <input
                    type='hidden'
                    name='mauticform[formId]'
                    id='mauticform_trialrequest_id'
                    defaultValue={4}
                />
                <input
                    type='hidden'
                    name='mauticform[return]'
                    id='mauticform_trialrequest_return'
                />
                <input
                    type='hidden'
                    name='mauticform[formName]'
                    id='mauticform_trialrequest_name'
                    defaultValue='trialrequest'
                />
            </form>
        </div>
    )
}

export default GenericTrialForm









































































































































































































































































































































































































































































































































































